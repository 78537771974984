import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo'
import AuthUserContext from '../components/Session/AuthUserContext';
// import PasswordForgetForm from '../components/PasswordForget';
import { DeleteAccount, PasswordChange, Reservations, UserInfo, UserInfoUpdate } from '../components/Account'
import withAuthorization from '../components/Session/withAuthorization';
import CTASection from '../components/CTA Section'

import { Header } from '../components/Elements'

import { Button, Container, Grid, Icon, Sidebar, Menu, Segment, Message } from 'semantic-ui-react'
// import { graphql } from 'gatsby';

// handleHideClick = () => this.setState({ visible: false })
// handleShowClick = () => this.setState({ visible: true })
// handleSidebarHide = () => this.setState({ visible: false })

class SidebarExampleSidebar extends React.Component {
  state = { visible: false, active: false, activeItem: 'home' }

  // handleHideClick = () => this.setState({ visible: false })
  handleShowClick = () => this.setState({ 
    visible: !this.state.visible,
    // active: !this.state.active 
  })

  handleSidebarHide = () => this.setState({ visible: false })

  handleShowComponent = (e, { name }) => {
    // alert(name)
    this.setState({ activeItem: name })
  }

  render() {
    const { visible, active } = this.state

    return (
      <div style={{ height: '100%', fontFamily: 'Playfair Display' }}>
   <React.Fragment>
     <AuthUserContext.Consumer>
       {authUser => (
         <div>
        <Button.Group>
          <Button active={active} disabled={visible} onClick={this.handleShowClick}>
          Twoje Konto
          </Button>
          {/* <Button toggle disabled={visible} onClick={this.handleShowClick}></Button> */}
          {/* <Button disabled={!visible} onClick={this.handleHideClick}>
            Hide sidebar
          </Button> */}
        </Button.Group>

        <Sidebar.Pushable as={Segment} style={{ border: 'none', minHeight: '80vh' }}>
          <Sidebar
            as={Menu}
            animation='slide along'
            icon='labeled'
            // inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={visible}
            width='thin'
          >
            <Menu.Item 
              as='a' 
              name='home'
              onClick={ this.handleShowComponent }
              >
              <Icon name='home' />
              Home
            </Menu.Item>
            <Menu.Item 
              as='a' 
              name='info'
              onClick={ this.handleShowComponent }
              >
              <Icon name='id card outline' />
              Dane Osobiste
            </Menu.Item>
            <Menu.Item 
              as='a'
              name='orders'
              onClick={ this.handleShowComponent }
              >
              <Icon name='calendar alternate outline' />
              Rezerwacje
            </Menu.Item>
            <Menu.Item as='a'
                name='settings'
                onClick={ this.handleShowComponent }
              >
              <Icon name='setting' />
              Ustawienia
            </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher style={{ border: 'none', minHeight: '80vh', marginBottom: '5rem' }}>
            {/* <Segment basic padded> */}
                           
              {/* <Container> */}
                <Grid centered columns={2}>
                <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={14}>
                  {/* <div> */}
                    {/* <h1>Name : {authUser.displayName}</h1>
                    <h2>Email : {authUser.email}</h2>
                    <h3>UID : {authUser.uid}</h3>
                    <h3>Last sign-in : {authUser.metadata.lastSignInTime}</h3> */}
                    {/* <PasswordForgetForm /> */}
                    {/* <Segment padded='very' basic> */}

                    {{
                      'home': (
                        // <Transition duration={{ hide: 500, show: 500 }} visible={visible}>
                        <Segment basic>
                          <Header title='true' spaced='true'>User Info</Header>
                          <UserInfo />
                        </Segment>
                        // </Transition>
                      ),
                      'settings': (
                        <Container>
                        {/* // <Transition duration={{ hide: 500, show: 500 }} visible={visible}> */}
                         <Segment basic>
                          <Header title='true' spaced='true'>Zmień Hasło</Header>
                          <PasswordChange /> 
                        </Segment>
                        <Segment basic>
                          <Header title='true' spaced='true'>Usuń Konto</Header>
                          <DeleteAccount /> 
                        </Segment>
                        {/* // </Transition> */}
                        </Container>
                      ),
                      
                      'info': (
                        <Segment basic>
                          <Header title='true' spaced='true'>Zmiana Danych Osobistych</Header>
                          <UserInfoUpdate />
                        </Segment>
                      ),
                      'orders': (
                        <Segment basic>
                          <Reservations />
                        </Segment>
                      ),
                      default: (
                        <Segment basic>
                          <Header title='true' spaced='true'>Twoje Konto</Header>
                          <UserInfo />
                        </Segment>
                      )
                    }[this.state.activeItem]}
                    </Grid.Column>
                   </Grid>
                  {/* </div> */}
               {/* </Container> */}
            {/* </Segment> */}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
        </div>
    )}
      </AuthUserContext.Consumer>
    </React.Fragment>
   
  </div>

    )
  }
}



// const AccountPageBase = ({ firebase }) => {

  
// return (
//   <React.Fragment>
//     <AuthUserContext.Consumer>
//       {authUser => ( 
//         <div>
//         <Button.Group>
//           <Button  onClick={this.handleShowClick}>
//             Show sidebar
//           </Button>
//           <Button onClick={this.handleHideClick}>
//             Hide sidebar
//           </Button>
//         </Button.Group>

//         <Sidebar.Pushable as={Segment}>
//           <Sidebar
//             as={Menu}
//             animation='overlay'
//             icon='labeled'
//             inverted
//             onHide={this.handleSidebarHide}
//             vertical
//             // visible={visible}
//             width='thin'
//           >
//             <Menu.Item as='a'>
//               <Icon name='home' />
//               Home
//             </Menu.Item>
//             <Menu.Item as='a'>
//               <Icon name='gamepad' />
//               Games
//             </Menu.Item>
//             <Menu.Item as='a'>
//               <Icon name='camera' />
//               Channels
//             </Menu.Item>
//           </Sidebar>

//           <Sidebar.Pusher>
//             <Segment basic>
//               <Header as='h3'>Application Content</Header>
//               <Image src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
            
//               <div>
//                 <h1>Name : {authUser.displayName}</h1>
//                 <h2>Email : {authUser.email}</h2>
//                 <h3>UID : {authUser.uid}</h3>
//                 <h3>Last sign-in : {authUser.metadata.lastSignInTime}</h3>
//                 {/* <PasswordForgetForm /> */}
//                 <PasswordChangeForm />
//                 <br />
//                 <UserInfoUpdate />
//               </div>
            
//             </Segment>
//           </Sidebar.Pusher>
//         </Sidebar.Pushable>
//       </div>





//       )}
//       {/* {authUser => {


//       const carts = firebase.firestore().collection('carts')

//       if (authUser) {
//         console.log('33[1;36m' + ` cure : `, authUser)
//         carts.doc(authUser.uid)
//         .onSnapshot(doc => {
//             var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
//             console.log(source, " data: ", doc.data());
//             // console.log('33[1;36m' + ` user : `, this.state.user)
//             this.setState({
//               test: doc.data()
//             })
    
//         })

//       console.log('33[1;36m' + ` carts : `, carts)

//       }


//       }} */}
//     </AuthUserContext.Consumer>
//   </React.Fragment>
// )
// }

const authCondition = authUser => !!authUser;

// const AccountPage = withAuthorization(authCondition)(AccountPageBase);
const SidebarExample = withAuthorization(authCondition)(SidebarExampleSidebar);




export default () => (
  <Layout>
    <SEO title='Twoje Konto' />
    {/* <AccountPage /> */}
    <SidebarExample />
    <CTASection />
  </Layout>
);



// import React, { Component, Fragment } from 'react';

// import Navigation from './Navigation';
// import getFirebase from './Firebase';
// import FirebaseContext from './Firebase/FirebaseContext';
// import withAuthentication from './Session/withAuthentication';

// class Layout extends Component {
//   state = {
//     firebase: null,
//   };

//   componentDidMount() {
//     const app = import('firebase/app');
//     const auth = import('firebase/auth');
//     const database = import('firebase/database');
//     const firestore = import('firebase/firestore');

//     Promise.all([app, auth, database, firestore]).then(values => {
//       const firebase = getFirebase(values[0]);

//       this.setState({ firebase });
//     });
//   }

//   render() {
//     return (
//       <FirebaseContext.Provider value={this.state.firebase}>
//         <AppWithAuthentication {...this.props} />
//       </FirebaseContext.Provider>
//     );
//   }
// }

// const AppWithAuthentication = withAuthentication(props => (
//   <Fragment>
//     <Navigation />
//     <hr />
//     {props.children}
//   </Fragment>
// ));

// export default Layout;







// const NotFoundPage = () => {
//   return (
//       <UserStore>
//         <div className="app">
//           <Nav />
//           <Body />
//         </div>
//       </UserStore>
//   )

// }

// export default NotFoundPage

